/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure } from '@mybridge/ui/hooks';
import { useRouter } from 'next/router';
import { forwardRef, useEffect } from 'react';
import { DashboardReelModal } from '../reel-modal';

export const GlobalReelContainer = forwardRef(({ ...props }, ref) => {
  const { query, back } = useRouter();
  const reel = query?.reel;
  const page = query?.page;
  const reelModalDisc = useDisclosure();
  useEffect(() => {
    if (reel?.length) {
      reelModalDisc.onOpen();
    } else {
      // reelModalDisc.onClose();
    }
  }, [reel]);

  const handleClose = () => {
    back?.();
  };

  return (
    <>
      {reel?.length > 0 ? (
        <DashboardReelModal
          {...reelModalDisc}
          onClose={handleClose}
          reelId={reel}
          page={page}
        />

      ) : (
        ''
      )}
    </>
  );
});
