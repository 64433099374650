/* eslint-disable @nx/enforce-module-boundaries */
import { ExpandIcon } from '@mybridge/icons/Expand';
import { Card, CloseButton, IconButton, Image, Scrollable } from '@mybridge/ui';
import { MyBridgeLogoMain } from '@mybridge/icons/MyBridgeLogo';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack, VStack } from '@mybridge/ui/layout';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Spinner } from '@mybridge/ui/spinner';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { PostBoxContext, usePostBoxContext } from 'v4/components/post/context';
import { getPostFunc } from 'v4/lib/api/post';
import { getUserFullName } from 'v4/lib/commons';
import { DashboardPostBox } from '../feed/post-box';
import { isMobile } from 'react-device-detect';
import { BackArrow } from '@mybridge/icons';
import { ModalreelDisplay } from 'v4/components/post/modal-Reel-display';
import { fetchReels } from 'v4/lib/api/reels';
import { Skeleton } from '@mybridge/ui/skeleton';
import { PostBoxFooter } from 'v4/components/post/footer';
import { PostBoxInsightPlate } from 'v4/components/post/insight-plate';
import { ReelBoxFooterActions } from 'v4/components/post/reel-footer-actions';
import styles from './reelContainer.module.scss';

export const DashboardReelContainer = ({
  page,
  reelId,
  onClose,
  setHasMedia,
  ...props
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [showMediaPost, setShowMediaPost] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(page));

  const [expanded, setExpanded] = useState(false);
  const [activeSliderReelId, setActiveSliderReelId] = useState(reelId);
  const [reelsData, setReelsData] = useState([]);
  const [isNextPageNull, setIsNextPageNull] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [oncePlayed, setOncePlayed] = useState(false);
  const playerRefs = useRef([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const { deletedPosts } = useSelector((state) => state.posts ?? {});

  useEffect(() => {
    fetchReelsDetails(page);
  }, []);

  const fetchReelsDetails = async (page, isFetchingNext = false) => {
    await fetchReels(Number(page), 6)
      .then(async (reelResult) => {
        if (reelResult?.results?.length > 0) {
          if (!isFetchingNext) {
            const startIndex = reelResult.results.findIndex(
              (item) => item.id === Number(reelId)
            );
            if (startIndex > -1) {
              let data =
                startIndex !== -1 ? reelResult.results.slice(startIndex) : [];
              setReelsData(data);
              playCurrentReels();
              if (data.length <= 2) {
                reelResult.next !== null &&
                  fetchReelsDetails(currentPage + 1, true);
              }
            }
          } else {
            setCurrentPage(page);
            setReelsData([...reelsData, ...reelResult?.results]);
          }
          setIsNextPageNull(reelResult.next === null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // query for fetching single post
  const { refetch: fetchPost, data } = useQuery(
    ['getPostDetail', activeSliderReelId],
    getPostFunc,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const postBoxContext = usePostBoxContext({
    post: data,
    initialFetchComments: true,
  });
  const { refetch, refetchComments, post, hasMedia } = postBoxContext ?? {};

  useEffect(() => {
    setHasMedia?.(true);
  }, [hasMedia]);

  useEffect(() => {
    if (deletedPosts?.includes?.(post?.id)) {
      props?.onClose?.();
    }
  }, [deletedPosts, post]);

  useEffect(() => {
    setTimeout(() => {
      refetch?.();
      refetchComments?.();
    }, 0);
  }, [activeSliderReelId]);

  useEffect(() => {
    if (!oncePlayed) {
      if (reelsData.length > 0) {
        setOncePlayed(true);
        setTimeout(() => {
          const currentRef = playerRefs.current[activeIndex];

          if (currentRef && currentRef?.getInternalPlayer) {
            currentRef?.getInternalPlayer()?.play();
          }
        }, 1000);
      }
    }
  }, [reelsData]);

  useEffect(() => {
    // Pause all videos
    if (playerRefs?.current?.length > 0) {
      playerRefs.current.forEach((playerRef, index) => {
        if (playerRef && playerRef.getInternalPlayer) {
          playerRef.getInternalPlayer().pause();
        }
      });
    }

    // Play the current video
    const currentRef = playerRefs.current[activeIndex];
    if (currentRef && currentRef?.getInternalPlayer) {
      currentRef?.getInternalPlayer()?.play();
    }
  }, [activeIndex]);

  const setPlayerRef = (index) => (ref) => {
    playerRefs.current[index] = ref;
  };

  useEffect(() => {
    const getLocalStorage = localStorage.getItem('commentCLicked');
    setShowMediaPost(getLocalStorage);
    fetchPost?.();
  }, [activeSliderReelId]);

  const handleOnClose = (e) => {
    localStorage.removeItem('commentCLicked');
    onClose?.();
  };
  const handlechangeSwipe = (slider) => {
    setActiveIndex(slider.activeIndex);
    setActiveSliderReelId(reelsData[slider?.activeIndex]?.id);
    if (reelsData.length - slider?.activeIndex <= 2 && !isNextPageNull) {
      fetchReelsDetails(currentPage + 1, true);
    }
    router.replace(
      {
        pathname: router.pathname,
        query: {
          reel: reelsData[slider?.activeIndex]?.id,
          page: page,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const postModalLink = (id) => `?reel=${id}`;

  const handleCommentClicked = () => {
    setExpanded(!expanded);
  };

  return (
    <PostBoxContext.Provider
      value={{
        ...(postBoxContext ?? {}),
        postModalLink,
      }}
    >
      {/* {!hasMedia ? (
        <ModalHeader borderBottom="1px solid #ddd" px={2}>
          <HStack>
            {isMobile && <BackArrow onClick={handleOnClose} />}
            <Heading
              size={['sm', 'md']}
              flex={1}
              textAlign="center"
              color="#3D5A80"
              fontWeight="400"
              zIndex={2}
            >
              {getUserFullName(post?.author)}&apos;s Post
            </Heading>
            {!isMobile && <CloseButton onClick={handleOnClose} />}
          </HStack>
        </ModalHeader>
      ) : null} */}
      <ModalBody p={0} className="reelModalContainer">
        {loading ? (
          <HStack
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            zIndex="5"
            bg="white"
          >
            <Spinner size="xl" color="black" />
          </HStack>
        ) : (
          ''
        )}
        <HStack
          flexDirection={['column', 'column', 'row']}
          alignItems="stretch"
          spacing={0}
          minH={'100vh'}
          pos="relative"
        >
          {/* {hasMedia ? ( */}
          <>
            <HStack
              pos="fixed"
              top="0"
              left="0"
              right="0"
              zIndex="2"
              px={2}
              maxW={'100px'}
            >
              <HStack bg="transparent" color="white">
                {!isMobile && <CloseButton onClick={handleOnClose} />}
              </HStack>

              <Box p={2}>
                <Link href="/" as="/">
                  <MyBridgeLogoMain width={isMobile ? '30px' : '44px'} />
                </Link>
              </Box>
            </HStack>
            <HStack
              // flex={"1 1 80%"}
              flex={1}
              bg="black"
              maxH={expanded ? '100vh' : ['50vh', '50vh', '100vh']}
              w="100%"
              maxW={['100%', '100%', expanded ? '100%' : '100%']}
              position="sticky"
              top={0}
              justifyContent="center"
              spacing={0}
              overflowY="hidden"
              _hover={{ overflowY: 'auto' }}
            >
              <div
                className={
                  expanded ? styles.container : styles.expendedContainer
                }
              >
                <Swiper
                  navigation
                  modules={[Navigation]}
                  onActiveIndexChange={handlechangeSwipe}
                  slidesPerView={1}
                >
                  <div>
                    {reelsData.map?.((post, index) => (
                      <SwiperSlide key={index}>
                        <div className={styles.mainReelSection}>
                          <div className={styles.mediaContainer}>
                            <ModalreelDisplay
                              reelIndex={index}
                              activeIndex={activeIndex}
                              setPlayerRef={() => setPlayerRef(index)}
                              post={post}
                              isReel={true}
                              fullScreen={expanded}
                            />
                          </div>
                          <div className={styles.footerAction}>
                            <PostBoxFooter>
                              {post?.id && (
                                <div className={styles.childContainer}>
                                  <PostBoxInsightPlate
                                    isActive={expanded}
                                    handleCommentClicked={handleCommentClicked}
                                    isReel={true}
                                  />
                                  <ReelBoxFooterActions
                                    isActive={expanded}
                                    handleCommentClicked={handleCommentClicked}
                                    isReel={true}
                                  />
                                </div>
                              )}
                            </PostBoxFooter>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
              </div>
            </HStack>
          </>
          {/* ) : (
            ''
          )} */}
          <Box
            flex={['auto', 'auto', 1]}
            maxW={expanded ? '31%' : 0}
            maxH="100%"
            overflowY="hidden"
          >
            {post?.id ? (
              <DashboardPostBox
                post={post}
                isInFrame={true}
                showMedia={post?.type === 3 || showMediaPost ? true : false}
                showComments={true}
                context={postBoxContext}
              />
            ) : (
              <Skeleton
                maxW={'100%'}
                maxH="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner />
              </Skeleton>
            )}
          </Box>
        </HStack>
      </ModalBody>
    </PostBoxContext.Provider>
  );
};
